import React from "react";

/**
 * Index (unused : redirect to home)
 */
class Index extends React.Component {
	render() {
		return (
			<div></div>
		);
	}
}

export default Index;